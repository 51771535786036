@import "variables.scss";
@import "mixins.scss";
@import "navbar.scss";
@import "masthead.scss";
@import "buttons.scss";
@import "about.scss";
@import "projects.scss";
@import "signup.scss";
@import "contact.scss";
@import "articles.scss";

body {
  font-family: "Nunito";
  letter-spacing: 0.0625em;
}

a {
  color: $primary;
  &:focus,
  &:hover {
    text-decoration: none;
    color: darken($primary, 20%);
  }
}
.textlink {
  color: $primary !important;
  &:focus,
  &:hover {
    text-decoration: underline !important;
    color: darken($primary, 20%) !important;
  }
  cursor: pointer;
}

.bg-black {
  background-color: $black !important;
}

.bg-primary {
  background-color: $primary !important;
}

.text-primary {
  color: $primary !important;
}

.img-overlay {
  height: 100% !important;
  width: 100% !important;
  position: absolute;
  background: rgba(70, 70, 70, 0.5);
}

footer {
  padding: 5rem 0;
}
