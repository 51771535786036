.navbar {
  min-height: 56px;
  background-color: $white;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none;
  background-color: transparent;
  transition: background-color 0.3s ease-in-out;

  .navbar-toggler {
    font-size: 80%;
    padding: 0.75rem;
    color: $primary;
    border: 1px solid $primary;
    &:focus {
      outline: none;
    }
  }

  .navbar-brand {
    font-weight: 700;
    color: fade-out($white, 0.5);
    padding: 0.9rem 0;
  }
  .navbar-nav {
    .nav-item {
      &:hover {
        color: fade(white, 80%);
        outline: none;
        background-color: transparent;
      }
      &:active,
      &:focus {
        outline: none;
        background-color: transparent;
      }
    }
  }

  .nav-link-foo {
    transition: none;
    padding: 2rem 1.5rem;
    color: fade-out($white, 0.5);
    &:hover {
      color: fade-out($white, 0.25);
    }
    &:active {
      color: white;
    }
  }
  &.navbar-white {
    .navbar-brand {
      color: $white !important;
    }
    .nav-link {
      color: $white !important;
    }
    .navbar-toggler {
      border-color: rgba(255, 255, 255, 0.5) !important;
    }
  }
  &.navbar-shrink {
    background-color: $white;
    .navbar-brand {
      color: $black;
    }
  }
}
